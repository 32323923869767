import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Col, Form, Image, Nav, Row } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { Country, State, City }  from 'country-state-city';
import axios from "axios";
import {
  coachUpdate,
  getCountries,
  getLanguages,
  getStates,
  coachAddLanguage,
  coachDeleteLanguage,
  coachImage,
} from "../../../utils/api";
import Camera from "../../../assets/images/Camera";
import Upload from "rc-upload";
import { WithContext as ReactTags } from "react-tag-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import Pic from "../../../assets/images/icons/user.png";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  emailid: Yup.string().email("Invalid email").required("Email is required"),
});

const initialValues = {
  name: "",
  emailid: "",
  gender: "",
  country_id: "",
  state_id: "",
  city: "",
  zipcode: "",
  languages: [],
};

const CoachProfilePersonalInformationComponent = (props) => {
  const navigate = useNavigate();
  const { user, getUserFromToken } = useUserContext();
  const [isLoading, setisLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [languages, setlanguages] = useState([]);
  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [img, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tags, setTags] = useState([]);
  const [deletedTags, setdeletedTags] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const getData = async () => {
    const lang = await getLanguages();
    setlanguages(
      lang?.data?.data?.map((i) => ({
        id: i?.name,
        text: i?.name,
        language_id: i?.id,
      }))
    );

    const country = await getCountries();    
    
    setcountries(
      country?.data?.data?.map((i) => ({ value: i?.id, label: i?.name }))
    );

    const state = await getStates(selectedCountry?.value);
    setstates(
      state?.data?.data?.map((i) => ({ value: i?.id, label: i?.name }))
    );

    setisLoading(false);
  };

  useEffect(() => {
    getData();
  }, [selectedCountry]);

  useEffect(() => {
    setTags(
      user?.languages?.map((i) => ({
        id: i?.name,
        text: i?.name,
        language_id: i?.id,
      })) || []
    );
    setPhone(user?.phone);

    if (user?.country_id) {
      setSelectedCountry({
        value: user?.country_id,
        label: user?.country_name,
      });
    }

    if (user?.state_id) {
      setselectedState({ value: user?.state_id, label: user?.state_name });
    }
  }, [user]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleFormSubmit = async (values) => {
    try {
      setisLoading(true);
      const { name, gender, city, zipcode } = values;
      // handle general info
      const formdata = new FormData();
      formdata.append("name", name);

      if (phone && phone !== "0") {
        formdata.append("phone", Number(phone));
      }

      if (gender) {
        formdata.append("gender", gender);
      }

      if (selectedCountry?.value) {
        formdata.append("country_id", selectedCountry?.value);
      }

      if (selectedState?.value) {
        formdata.append("state_id", selectedState?.value);
      }

      formdata.append("city", city )
      formdata.append("zipcode", zipcode)

      await coachUpdate(formdata);

      if (img) {
        const imgData = new FormData();
        imgData.append("profile_pic", img);
        await coachImage(imgData);
      }

      // handle languages
      if (tags?.length > 0) {
        const addLanguage = new FormData();
        addLanguage.append(
          "language_ids",
          `[${tags
            ?.filter((j) => j?.type !== "new")
            ?.map((i) => i?.language_id)}]`
        );
        await coachAddLanguage(addLanguage);
      }

      if (tags?.filter((i) => i?.type === "new")?.length > 0) {
        const addSkill = new FormData();
        addSkill.append(
          "new_languages",
          `[${tags
            ?.filter((j) => j?.type === "new")
            ?.map((i) => `"${i?.text}"`)}]`
        );
        await coachAddLanguage(addSkill);
      }

      if (deletedTags?.length > 0) {
        const deleteLanguage = new FormData();
        deleteLanguage.append(
          "language_ids",
          `[${deletedTags?.map((i) => i?.language_id)}]`
        );
        await coachDeleteLanguage(deleteLanguage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setisLoading(false);
    }

    getUserFromToken();
    setisLoading(false);
    navigate("/coach/about-yourself");
    // window.location.assign("/coach/about-yourself")
  };

  const onClickNext = () => {
    navigate("/coach/about-yourself");
    // window.location.assign("/coach/about-yourself")
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    for (const [key, value] of Object.entries(user || {})) {
      formik?.setFieldValue(key, value);
    }
  }, [user]);

  const onChangeImage1 = async (file) => {
    setImage(file);

    const formData = new FormData();
    formData.append("profile_pic", file);

    await coachImage(formData);
    getUserFromToken();
  };

  const handleDelete = (i) => {
    setdeletedTags([...deletedTags, tags.find((_, index) => index === i)]);
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    const newTag = { ...tag, type: "new" };
    setTags([...tags, newTag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const getImage = () => {
    // console.log("image called");
    if (img) {
      return URL.createObjectURL(img);
    }

    if (!user?.profile_pic) {
      return Pic;
    }
    if (user.login_type === "Linkedin") {
      console.log("Linkedin");
      return user.profile_pic;
    }

    return `${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${user?.profile_pic}`;
  };

  const statesOptions = states.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const countryOptions = countries.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleFileChange = (file) => {
    setSelectedFile(file);
    setShowModal(true); // Show the modal for confirmation
  };

  const confirmUpload = async () => {
    setImage(selectedFile);
    setShowModal(false); // Close the modal

    // Prepare FormData
    const formData = new FormData();
    formData.append("profile_pic", selectedFile);

    try {
      // Call the API
      await coachImage(formData);
      getUserFromToken();
    } catch (error) {
      console.error("Error uploading image:", error.response?.data || error.message);
      alert("Failed to upload the image. Please try again.");
    }
  };

  const cancelUpload = () => {
    setShowModal(false);
    setSelectedFile(null); // Reset the selected file
  };

  return (
    <>
      <section className="coach_my_activity py-3">
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            {window.innerWidth < 500 ? (
              <div>
                <Col className="col">
                  <Nav
                    variant="pills"
                    className="wizard_nav justify-content-center"
                  >
                    <Nav.Item className="active">
                      <span className="wn_num">1</span>
                      <span> Personal Information</span>
                    </Nav.Item>
                  </Nav>
                </Col>
              </div>
            ) : (
              <Col className="col">
                <Nav
                  variant="pills"
                  className="wizard_nav justify-content-between"
                >
                  <Nav.Item className="active">
                    <span className="wn_num">1</span>
                    <span>Personal Information</span>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/about-yourself">
                      <span className="wn_num">2</span>
                      <span>About Yourself</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/coaching-details">
                      <span className="wn_num">3</span>
                      <span>Coaching Details</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/professional-details">
                      <span className="wn_num">4</span>
                      <span>Professional Details</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>
            )}
          </Row>
          {!isLoading && (
            <Form
              className="profile_edit_forms mt-4"
              onSubmit={formik?.handleSubmit}
            >
              <FormikProvider value={formik}>
                <Row>
                  <Col sm="12" lg="4">
                    <div className="p_p_user_img mx-auto">
                      <Upload onStart={handleFileChange}>
                        <Image
                          width={200}
                          height={200}
                          src={getImage()}
                          roundedCircle
                          alt=""
                        />
                        <Button variant="" className="upload_img_btn">
                          <Camera />
                        </Button>
                      </Upload>

                      {/* Confirmation Modal */}
                      <Modal show={showModal} onHide={cancelUpload}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Upload</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to upload this image?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={cancelUpload}>
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={confirmUpload}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>

                    </div>
                  </Col>
                  <Col sm="12" lg="8">
                    <Col sm="12" lg="12">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Name*</Form.Label>                   -
                        <Field
                          type="text"
                          placeholder="Enter Name"
                          name="name"
                          as={Form.Control}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div
                            name="name"
                            component="div"
                            className="text-danger"
                          >
                            {formik.errors?.name}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm="12" lg="12">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Email*</Form.Label>
                        <Field
                          disabled
                          type="text"
                          placeholder="ss100ev@gmail.com"
                          name="emailid"
                          as={Form.Control}
                        />
                        {formik.touched.emailid && formik.errors.emailid && (
                          <div
                            name="emailid"
                            component="div"
                            className="text-danger"
                          >
                            {formik.errors?.emailid}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        name="phone"
                        as={Form.Control}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Gender</Form.Label>
                      <div className="d-flex py-2">
                        <Form.Check
                          type="radio"
                          name="gender"
                          label="Male"
                          value="Male"
                          checked={formik?.values.gender === "Male"}
                          onChange={() =>
                            formik?.setFieldValue("gender", "Male")
                          }
                          className="mx-2"
                        />
                        <Form.Check
                          type="radio"
                          name="gender"
                          label="Female"
                          value="Female"
                          checked={formik?.values.gender === "Female"}
                          onChange={() =>
                            formik?.setFieldValue("gender", "Female")
                          }
                          className="mx-2"
                        />
                        <Form.Check
                          type="radio"
                          name="gender"
                          label="Other"
                          value="Other"
                          checked={formik?.values.gender === "Other"}
                          onChange={() =>
                            formik?.setFieldValue("gender", "Other")
                          }
                          className="mx-2"
                        />
                      </div>
                      {formik.touched.gender && formik.errors.gender && (
                        <div
                          name="gender"
                          component="div"
                          className="text-danger"
                        >
                          {formik.errors?.gender}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Country</Form.Label>
                      <Select
                        defaultValue={selectedCountry}
                        onChange={setSelectedCountry}
                        options={countries}
                        placeholder="Select a country..."
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>State</Form.Label>
                      <Select
                        defaultValue={selectedState}
                        onChange={setselectedState}
                        options={states}
                        placeholder="Select a state..."
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>City</Form.Label>
                      <Field
                        type="text"
                        placeholder="Nagpur"
                        name="city"
                        as={Form.Control}
                      />
                      {/* <Select
                        defaultValue={selectedCountry}
                        onChange={setSelectedCountry}
                        options={countries}
                        placeholder="Select a country..."
                      /> */}
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="6">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Zip Code</Form.Label>
                      <Field
                        
                        type="number"
                        placeholder="440024"
                        name="zipcode"
                        as={Form.Control}
                      />
                      {/* <Select
                        defaultValue={selectedState}
                        onChange={setselectedState}
                        options={states}
                        placeholder="Select a state..."
                      /> */} 
                    </Form.Group>
                  </Col>

                  <div>
                    <ReactTags
                      placeholder="Language you speak"
                      tags={tags}
                      suggestions={languages}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      classNames={{
                        tags: "tagsClass",
                        tagInput: "tagInputClass",
                        tagInputField: "tagInputFieldClass",
                        selected: "selectedClass",
                        tag: "tagClass",
                        remove: "removeClass",
                        suggestions: "suggestionsClass",
                        activeSuggestion: "activeSuggestionClass",
                        editTagInput: "editTagInputClass",
                        editTagInputField: "editTagInputField",
                        clearAll: "clearAllClass",
                      }}
                    />
                  </div>

                  <Col sm="12" lg="12">
                    <div className="p-0 text-end d-flex justify-content-end">
                      <Button
                        className={
                          window.innerWidth < 500 ? "px-5 me-4" : "px-5 me-1"
                        }
                        variant="outline-success"
                        type="button"
                        onClick={() => onClickNext()}
                      >
                        Next
                      </Button>
                      <Button
                        className={window.innerWidth < 500 ? "px-3" : "px-5"}
                        variant="success"
                        type="submit"
                      >
                        {isLoading ? <Loader /> : "Save & Continue"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </FormikProvider>
            </Form>
          )}
        </Container>
      </section>
    </>
  );
};

export default CoachProfilePersonalInformationComponent;
