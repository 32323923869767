import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";
import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
import send_img from "../../../assets/images/icons/send.png";
import Modal from "react-awesome-modal";
import {
  getConversationList,
  sendMessage,
  messageSeen,
  oldMessages,
  deleteConversation,
} from "../../../utils/api";
import io from "socket.io-client";
import { socket_url } from "../../../utils/api/axios";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CoachMessagesComponent() {
  const listInnerRef = useRef();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [conversations, setConversations] = useState([]);
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [conversationDetail, setConversationDetail] = useState({});
  const [openConversation, setOpenConversation] = useState(false);
  const [allMessages, setAllMessages] = useState([]);

  const [id, setId] = useState("");
  const [currPage, setCurrPage] = useState(2);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userDetails, setUserDetails] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConversations = conversations.filter((convo) =>
    convo.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getData = async () => {
    const list = await getConversationList();
    if (list.data.status === true) {
      setConversations(list?.data?.data);
    } else {
      toast.error("Please login to do this action");
      // navigate("/");
    }
  };

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    console.log(user, 'user');
    setUserDetails(user)

    const socket = io(socket_url);
    setSocket(socket);
    socket.on("connect", () => {
      console.log(socket.connected);
    });
    getData();
    // socket emit for getting realtime conversation list update
    socket.on("notification_count", (data) => {
      // console.log(data);
      // setConversations(data.conversation_list);
      getData();
    });
    socket.emit("message_notification", {
      id: user?.coach_id,
      userType: user.userType,
    });
    // socket on for realtime message get
    socket.on("message", (data) => {
      // console.log(data);
      setAllMessages(data.reverse());
    });
  }, []);

  const send_message_socket = async (convo) => {
    setConversationDetail(convo);
    setOpenConversation(true);
    setId(convo?.user_id);
    socket.emit("send_message", {
      coach_id: convo?.coach_id,
      user_id: convo?.user_id,
      room_id: convo?.id,
    });

    const seen = await messageSeen({ user_id: convo?.user_id });
    if (seen) {
      getData();
      socket.emit("message_notification", {
        id: convo?.coach_id,
        userType: "coach",
      });
    }
  };

  const allSocket = () => {
    socket.emit("send_message", {
      coach_id: conversationDetail?.coach_id,
      user_id: conversationDetail?.user_id,
      room_id: conversationDetail?.id,
    });
    socket.emit("message_notification", {
      id: conversationDetail?.user_id,
      userType: "user",
    });
    socket.on("message", (data) => {
      // console.log(data);
    });
  };

  const sendMessages = async (data) => {
    const sent_message = await sendMessage(data);
    if (sent_message.data.status === true) {
      allSocket();
    } else {
      toast.error("Please login to do this action");
      // navigate("/");
    }
  };

  const handleSendMessage = async (e) => {
    const data = {
      user_id: conversationDetail?.user_id,
      message: message,
    };
    e.preventDefault();
    sendMessages(data);
    setMessage("");
  };

  const old_messages = async () => {
    const old_messages_data = await oldMessages({
      id: conversationDetail?.user_id,
      limit: 20,
      page: currPage,
    });
    setAllMessages([...old_messages_data.data.data, ...allMessages]);
    if (!old_messages_data.data.data.length) {
      setWasLastList(true);
      setCurrPage(prevPage);
      return;
    } else {
    }
    setPrevPage(currPage);
  };

  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop } = listInnerRef.current;
      // console.log(scrollTop);
      if (scrollTop < -195) {
        if (!wasLastList && prevPage !== currPage) {
          setCurrPage(currPage + 1);
          old_messages();
        }
      }
    }
  };

  const get_GMT_Time = (time) => {
    const get_hour = new Date(time).getHours();
    const get_minutes = new Date(time).getMinutes();
    const am_pm = get_hour > 12 ? "PM" : "AM";
    const time_string = `${get_hour > 12 ? "0" + get_hour - 12 : get_hour
      }:${get_minutes} ${am_pm}`;
    return time_string;
  };

  const delete_conversation = async () => {
    const deleted = await deleteConversation(conversationDetail?.id);
    if (deleted.data.status === true) {
      toast.success("Conversation deleted successfully!");
      setOpenConversation(false);
      setConversationDetail({});
      getData();
    } else {
      toast.error(deleted.data.message);
      navigate("/");
    }
  };
  const defaultUserImage = (e) => {
    e.target.src = live_session_img1;
  }
  return (
    <>
      <section>
        <Container>
          <Row>
            {/* User Messages List */}
            {openConversation === false && window.innerWidth < 991 && (
              <div className="chat_msg_card h-100">
                <Col lg="4 m-auto">
                  <div className="msg_user_card">
                    <div className="msg_user_card_header">
                      <p className="fs-16 fw-500 m-0">Messages</p>
                    </div>
                    <input
                      type="text"
                      placeholder="Search User"
                      value={searchTerm}
                      onChange={handleSearch}
                      className="search-box"
                      style={{ padding: '5px', width: '100%', border: '1px solid #e3e3e3' }}
                    />
                    <div className="msg_user_card_body">
                      <Nav className="msg_user_list flex-column">
                        {filteredConversations.length > 0 ? (
                          filteredConversations.map((convo, index) => (
                            <Nav.Item
                              className={convo?.user_id === id ? "active" : ""}
                              key={convo?.user_id}
                              onClick={() => {
                                send_message_socket(convo);
                              }}
                              value={convo}
                            >
                              <div className="msg_user" value={convo}>
                                <div
                                  className="d-flex align-items-center"
                                  value={convo}
                                >
                                  <img
                                    className="msg_user_img"
                                    src={
                                      convo?.profile_pic !== null
                                        ? `${
                                            convo.login_type === "Linkedin"
                                              ? convo.profile_pic
                                              : process.env.REACT_APP_USER_PROFILE_FOLDER_PATH
                                          }${convo?.profile_pic}`
                                        : live_session_img1
                                    }
                                    alt=""
                                    value={convo}
                                    onError={(e) => defaultUserImage(e)}
                                  />
                                  <div className="msg_user_nm" value={convo}>
                                    <h5 value={convo}> {convo.name} </h5>
                                    <p value={convo}>
                                      {convo.last_message.substring(0, 15)}...
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="msg_user_tm text-center"
                                  value={convo}
                                >
                                  <p value={convo}>
                                    {convo.last_message !== null &&
                                      convo.last_message.substring(0, 15)}
                                    ...
                                  </p>
                                  {convo?.unseen_message_count > 0 &&
                                    convo?.last_message_from === "user" && (
                                      <span className="ttl_msg" value={convo}>
                                        {convo?.unseen_message_count}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </Nav.Item>
                          ))
                        ) : (
                          <div className="no_new_message">
                            <p>No New Message! Reach out to your users.</p>
                          </div>
                        )}
                      </Nav>
                    </div>
                    {/*<div className="msg_user_card_body">
                      <Nav className="msg_user_list flex-column">
                        {conversations.length > 0 ? (
                          conversations.map((convo, index) => (
                            <Nav.Item
                              className={convo?.user_id === id ? "active" : ""}
                              key={convo?.user_id}
                              onClick={() => {
                                send_message_socket(convo);
                              }}
                              value={convo}
                            >
                              <div className="msg_user" value={convo}>
                                <div
                                  className="d-flex align-items-center"
                                  value={convo}
                                >
                                  <img
                                    className="msg_user_img"
                                    src={
                                      convo?.profile_pic !== null
                                        ? `${convo.login_type == "Linkedin"
                                          ? convo.profile_pic
                                          : process.env
                                            .REACT_APP_USER_PROFILE_FOLDER_PATH
                                        }${convo?.profile_pic}`
                                        : live_session_img1
                                    }
                                    alt=""
                                    value={convo}
                                    onError={(e) => defaultUserImage(e)}
                                  />
                                  <div className="msg_user_nm" value={convo}>
                                    <h5 value={convo}> {convo.name} </h5>
                                    <p value={convo}>
                                      {convo.last_message.substring(0, 15)}...
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="msg_user_tm text-center"
                                  value={convo}
                                >
                                  <p value={convo}>
                                    {convo.last_message !== null && convo.last_message.substring(0, 15)}...
                                  </p>
                                  {convo?.unseen_message_count > 0 &&
                                    convo?.last_message_from === "user" && (
                                      <span className="ttl_msg" value={convo}>
                                        {" "}
                                        {convo?.unseen_message_count}{" "}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </Nav.Item>
                          ))
                        ) : (
                          <div className="no_new_message">
                            <p>No New Message! Reach out to your users.</p>
                          </div>
                        )}
                      </Nav>
                    </div>*/}
                  </div>
                </Col>
              </div>
            )}
            {window.innerWidth > 991 && (
              <Col lg="4 m-auto">
                <div className="msg_user_card">
                  <div className="msg_user_card_header">
                    <p className="fs-16 fw-500 m-0">Messages</p>
                  </div>
                  <input
                    type="text"
                    placeholder="Search User"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-box"
                    style={{ padding: '5px', width: '100%', border: '1px solid #e3e3e3' }}
                  />
                  <div className="msg_user_card_body">
                    <Nav className="msg_user_list flex-column">
                      {filteredConversations.length > 0 ? (
                        filteredConversations.map((convo, index) => (
                          <Nav.Item
                            className={convo?.user_id === id ? "active" : ""}
                            key={convo?.user_id}
                            onClick={() => {
                              send_message_socket(convo);
                            }}
                            value={convo}
                          >
                            <div className="msg_user" value={convo}>
                              <div
                                className="d-flex align-items-center"
                                value={convo}
                              >
                                <img
                                  className="msg_user_img"
                                  src={
                                    convo?.profile_pic !== null
                                      ? `${
                                          convo.login_type === "Linkedin"
                                            ? convo.profile_pic
                                            : process.env.REACT_APP_USER_PROFILE_FOLDER_PATH
                                        }${convo?.profile_pic}`
                                      : live_session_img1
                                  }
                                  alt=""
                                  value={convo}
                                  onError={(e) => defaultUserImage(e)}
                                />
                                <div className="msg_user_nm" value={convo}>
                                  <h5 value={convo}> {convo.name} </h5>
                                  <p value={convo}>
                                    {convo.last_message.substring(0, 15)}...
                                  </p>
                                </div>
                              </div>
                              <div
                                className="msg_user_tm text-center"
                                value={convo}
                              >
                                <p value={convo}>
                                  {convo.last_message !== null &&
                                    convo.last_message.substring(0, 15)}
                                  ...
                                </p>
                                {convo?.unseen_message_count > 0 &&
                                  convo?.last_message_from === "user" && (
                                    <span className="ttl_msg" value={convo}>
                                      {convo?.unseen_message_count}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </Nav.Item>
                        ))
                      ) : (
                        <div className="no_new_message">
                          <p>No New Message! Reach out to your users.</p>
                        </div>
                      )}
                    </Nav>
                  </div>                  
                  {/*<div className="msg_user_card_body">
                    <Nav className="msg_user_list flex-column">
                      {conversations.length > 0 ? (
                        conversations.map((convo, index) => (
                          <Nav.Item
                            className={convo?.user_id === id ? "active" : ""}
                            key={convo?.user_id}
                            onClick={() => {
                              send_message_socket(convo);
                            }}
                            value={convo}
                          >
                            <div className="msg_user" value={convo}>
                              <div
                                className="d-flex align-items-center"
                                value={convo}
                              >
                                <img
                                  className="msg_user_img"
                                  src={
                                    convo?.profile_pic !== null
                                      ? `${convo.login_type == "Linkedin"
                                        ? convo.profile_pic
                                        : process.env
                                          .REACT_APP_USER_PROFILE_FOLDER_PATH
                                      }${convo?.profile_pic}`
                                      : live_session_img1
                                  }
                                  alt=""
                                  onError={(e) => defaultUserImage(e)}
                                  value={convo}
                                />
                                <div className="msg_user_nm" value={convo}>
                                  <h5 value={convo}> {convo.name} </h5>
                                  <p value={convo}>
                                    {convo.last_message !== null && convo.last_message.substring(0, 15)}...
                                  </p>
                                </div>
                              </div>
                              <div
                                className="msg_user_tm text-center"
                                value={convo}
                              >
                                <p value={convo}>
                                  {" "}
                                  {get_GMT_Time(convo?.updated_at)}{" "}
                                </p>
                                {convo?.unseen_message_count > 0 &&
                                  convo?.last_message_from === "user" && (
                                    <span className="ttl_msg" value={convo}>
                                      {" "}
                                      {convo?.unseen_message_count}{" "}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </Nav.Item>
                        ))
                      ) : (
                        <div className="no_new_message">
                          <p>No New Message! Reach out to your users.</p>
                        </div>
                      )}
                    </Nav>
                  </div>*/}
                </div>
              </Col>
            )}
            {/* Message Box for One to One Chat */}

            <Col lg="8">
              <div className="h-100">
                {window.innerWidth < 991 && (
                  <Col>
                    <Button
                      variant="outline-success"
                      className="px-2"
                      size="sm"
                      onClick={() => setOpenConversation(false)}
                    >
                      Back
                    </Button>
                  </Col>
                )}
                <Col className="h-100">
                  {openConversation === true ?
                    <>
                      <div className="chat_msg_card h-100">
                      <div className="chat_msg_card_header">
                        <div className="d-flex align-items-center">
                          <img
                            className="msg_user_img"
                            src={
                              conversationDetail?.profile_pic !== null
                                ? `${conversationDetail.login_type == "Linkedin"
                                  ? conversationDetail.profile_pic
                                  : process.env
                                    .REACT_APP_USER_PROFILE_FOLDER_PATH
                                }${conversationDetail?.profile_pic}`
                                : live_session_img1
                            }
                            alt=""
                            onError={(e) => defaultUserImage(e)}
                          />
                          <h5 className="m-0"> {conversationDetail?.name} </h5>
                        </div>
                        <div className="d-flex align-items-center">
                          {/* Block Button */}
                          <Button variant="link" className="px-1">
                            <svg width="20" height="20" className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12 2C14.3065 1.99816 16.5419 2.79775 18.324 4.262L4.262 18.324C3.06257 16.8598 2.30366 15.0851 2.07362 13.2064C1.84358 11.3277 2.15186 9.42234 2.96259 7.71204C3.77332 6.00174 5.05314 4.55688 6.65308 3.54564C8.25303 2.53441 10.1073 1.9984 12 2ZM12 22C9.69353 22.0018 7.45808 21.2023 5.676 19.738L19.738 5.676C20.9374 7.14016 21.6963 8.91488 21.9264 10.7936C22.1564 12.6723 21.8481 14.5777 21.0374 16.288C20.2267 17.9983 18.9469 19.4431 17.3469 20.4544C15.747 21.4656 13.8927 22.0016 12 22Z" fill="#595a70" />
                            </svg>
                          </Button>
                          {/* Flag Button */}
                          <Button variant="link" className="px-1">
                            <svg
                              width="20"
                              height="20"
                              className="me-2"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.5 4H15.449C15.3286 2.90305 14.8084 1.88887 13.9878 1.15106C13.1671 0.413246 12.1035 0.00348639 11 0L4.5 0C3.30693 0.00132343 2.16311 0.475854 1.31948 1.31948C0.475854 2.16311 0.00132343 3.30693 0 4.5L0 22.5C0 22.8978 0.158035 23.2794 0.43934 23.5607C0.720644 23.842 1.10218 24 1.5 24C1.89782 24 2.27936 23.842 2.56066 23.5607C2.84196 23.2794 3 22.8978 3 22.5V14H9.551C9.67142 15.0969 10.1916 16.1111 11.0122 16.8489C11.8329 17.5868 12.8965 17.9965 14 18H19.5C20.6931 17.9987 21.8369 17.5241 22.6805 16.6805C23.5241 15.8369 23.9987 14.6931 24 13.5V8.5C23.9987 7.30693 23.5241 6.16311 22.6805 5.31948C21.8369 4.47585 20.6931 4.00132 19.5 4ZM3 4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H11C11.3978 3 11.7794 3.15804 12.0607 3.43934C12.342 3.72064 12.5 4.10218 12.5 4.5V9.5C12.5 9.89782 12.342 10.2794 12.0607 10.5607C11.7794 10.842 11.3978 11 11 11H3V4.5ZM21 13.5C21 13.8978 20.842 14.2794 20.5607 14.5607C20.2794 14.842 19.8978 15 19.5 15H14C13.6408 14.9985 13.2941 14.8682 13.0229 14.6328C12.7517 14.3973 12.574 14.0724 12.522 13.717C13.3917 13.4055 14.1442 12.8335 14.6771 12.0789C15.21 11.3243 15.4974 10.4238 15.5 9.5V7H19.5C19.8978 7 20.2794 7.15804 20.5607 7.43934C20.842 7.72064 21 8.10218 21 8.5V13.5Z"
                                fill="#595a70"
                              />
                            </svg>
                          </Button>
                          {/* Delete Button */}
                          <Button
                            variant="link"
                            className="px-1"
                            onClick={() => setVisible(true)}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_2377_4524)">
                                <path
                                  d="M21 4H17.9C17.6679 2.87141 17.0538 1.85735 16.1613 1.12872C15.2687 0.40009 14.1522 0.00145452 13 0L11 0C9.8478 0.00145452 8.73132 0.40009 7.83875 1.12872C6.94618 1.85735 6.3321 2.87141 6.1 4H3C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5C2 5.26522 2.10536 5.51957 2.29289 5.70711C2.48043 5.89464 2.73478 6 3 6H4V19C4.00159 20.3256 4.52888 21.5964 5.46622 22.5338C6.40356 23.4711 7.67441 23.9984 9 24H15C16.3256 23.9984 17.5964 23.4711 18.5338 22.5338C19.4711 21.5964 19.9984 20.3256 20 19V6H21C21.2652 6 21.5196 5.89464 21.7071 5.70711C21.8946 5.51957 22 5.26522 22 5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4ZM11 2H13C13.6203 2.00076 14.2251 2.19338 14.7316 2.55144C15.2381 2.90951 15.6214 3.41549 15.829 4H8.171C8.37858 3.41549 8.7619 2.90951 9.26839 2.55144C9.77487 2.19338 10.3797 2.00076 11 2ZM18 19C18 19.7956 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H9C8.20435 22 7.44129 21.6839 6.87868 21.1213C6.31607 20.5587 6 19.7956 6 19V6H18V19Z"
                                  fill="#595a70"
                                />
                                <path
                                  d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18Z"
                                  fill="#595a70"
                                />
                                <path
                                  d="M14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z"
                                  fill="#595a70"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2377_4524">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Button>
                          <Button
                            variant="link"
                            className="px-1"
                            onClick={() => {
                              setOpenConversation(false);
                              setConversationDetail({});
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => {
                                setOpenConversation(false);
                                setConversationDetail({});
                              }}
                            >
                              <path
                                opacity="0.5"
                                d="M7.47118 6.76256L8.00151 7.29289L8.53184 6.76256L13.8351 1.45926C14.0304 1.264 14.347 1.264 14.5422 1.45926C14.7375 1.65452 14.7375 1.97111 14.5422 2.16637L9.23895 7.46967L8.70862 8L9.23895 8.53033L14.5422 13.8336C14.7375 14.0289 14.7375 14.3455 14.5422 14.5407C14.347 14.736 14.0304 14.736 13.8351 14.5407L8.53184 9.23744L8.00151 8.70711L7.47118 9.23744L2.16788 14.5407C1.97262 14.736 1.65603 14.736 1.46077 14.5407C1.26551 14.3455 1.26551 14.0289 1.46077 13.8336L6.76407 8.53033L7.2944 8L6.76407 7.46967L1.46077 2.16637C1.26551 1.97111 1.26551 1.65452 1.46077 1.45926C1.65603 1.264 1.97262 1.264 2.16788 1.45926L7.47118 6.76256Z"
                                stroke="#14183E"
                                stroke-width="1.5"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div>

                      <div
                        className="chat_msg_card_body"
                        ref={listInnerRef}
                        onScroll={onScroll}
                      >
                        <ul className="chat_msg_list">
                          {allMessages.length > 0 &&
                            allMessages.map((item, index) => (
                              <li
                                key={index}
                                className={
                                  item?.message_from === "coach"
                                    ? "chat_msg_list_item by_slf"
                                    : "chat_msg_list_item"
                                }
                              >
                                <div>
                                  {item?.message}
                                </div>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "left",
                                    display: "flex",
                                  }}
                                >
                                  {/* {
                                        get_GMT_Time(item.created_at)
                                      } */}
                                  {new Date(item.created_at).getDate() <
                                    new Date().getDate()
                                    ? new Date(item.created_at).getDate() +
                                    "/" +
                                    (new Date(item.created_at).getMonth() +
                                      1) +
                                    "/" +
                                    new Date(item.created_at).getFullYear()
                                    : new Date(item.created_at).getHours() +
                                    ":" +
                                    new Date(item.created_at).getMinutes()}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="chat_msg_card_footer py-0 px-3">
                        <div className="chat_msg_textarea">
                          <InputGroup className="">
                            {/* <Form.Control
                                className="fs-14"
                                as="textarea"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              /> */}
                            <input type="text" className="fs-14 form-control p-0" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Write a message...." />
                            <Button
                              variant="outline-secondary"
                              id="button-addon1"
                              onClick={(e) => handleSendMessage(e)}
                            >
                              <img src={send_img} alt="" />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>
                      </div>
                    </>
                    :
                    <>
                      {window.innerWidth > 991 && (
                        <div className="chat_msg_card h-100">

                          <div className="h-100 position-relative p-5">
                            <div className="d-flex align-items-center justify-content-start">
                              {
                                console.log(userDetails, 'userDetails')
                              }
                              <img
                                src={
                                  userDetails.profile_pic ?
                                    process.env
                                      .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                    userDetails.profile_pic
                                    :
                                    live_session_img1
                                }
                                alt="profile pic"
                                className="rounded-circle"
                                height={100}
                                width={100}
                              />
                              <div className="ms-2">
                                <span>Welcome!</span>
                                <h2 className="fw-700">{userDetails.name}</h2>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p>Here are some quick action to get you started</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  }
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        visible={visible}
        onClickAway={() => setVisible(false)}
        height="250px"
        width="600px"
      >
        <div className="logout_modal text-center position-absolute start-50 top-50 translate-middle">
          <h3> Are you sure you want to logout ?</h3>
          <div className="logout_button_container mt-4">
            <button
              className="btn-success p-2 rounded me-3"
              onClick={() => {
                delete_conversation();
                setVisible(false);
              }}
            >
              {" "}
              <h5 className="m-0 px-4 fs-14 fw-400">Yes</h5>{" "}
            </button>
            <button
              className="btn-success p-2 rounded bg-secondary border-0 text-white"
              onClick={() => setVisible(false)}
            >
              {" "}
              <h5 className="m-0 px-4 fs-14 fw-400">No</h5>{" "}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CoachMessagesComponent;
