import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import update_availability from "../../../assets/images/icons/update_availability.png";

import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { useUserContext } from "../../../utils/hooks/UserContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { addAvailability } from "../../../utils/api";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCoachBookedAllSlots } from "../../../utils/api";
import moment from "moment";
import axios from "axios";
const baseURL = `${process.env.REACT_APP_LOCAL_BASE_URL}`;
function AddAvailabilitySlots() {
  const navigate = useNavigate();

  const { user } = useUserContext();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [slots, setSlots] = useState([{ id: 1, fromTime: "", toTime: "" }]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [updateSlots, setAllSlot] = useState([]);
  const [bookedEvents, setBookedEvents] = useState([]);

  const getListData = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control":
            "no-store, no-cache, must-revalidate, proxy-revalidate",
        },

        url: `${baseURL}/coach/program/coach-booked-slot-list?type=coach`,
      };
      axios(options).then((resp) => {
        // console.log(resp, "filter",resp.data.data.length);
        if (resp.data.data.length > 0) {
          setBookedEvents(resp.data.data);
          console.log("ddddddddddd", bookedEvents);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListData();
  }, []);
  const formatDate = (date) => {
    const isoString = date.toISOString(); // Get ISO format '2024-10-07T07:59:30.000Z'

    // Adjust it to match the required output
    const datePart = isoString.split("T")[0]; // '2024-10-07'
    const timePart = isoString.split("T")[1].split(".")[0]; // '07:59:30'

    return `${datePart}T${timePart}.000000`; // '2024-10-07T07:59:30.000000'
  };

  // const formatTime = (date, time) => {
  //   const isoString = date.toISOString(); // Get ISO format '2024-10-07T07:59:30.000Z'
  //   // Adjust it to match the required output
  //   const datePart = isoString.split("T")[0];

  //   return `${datePart}T${time}:00.000000`;
  // };

  // const updateSlot = async () => {
  //   console.log(":dd",updateSlots)
  //   if(updateSlots.length == 0){
  //       toast.error("please select time slot")
  //   }else{
  //     let counter = 0 ;
  //     for(let i = 0; i < updateSlots.length; i++){
  //       counter++;
  //       await addAvailability(updateSlots[i]);
  //     }
  //     if(counter == updateSlots.length){
  //       toast.success("Slot added successfully!");
  //       navigate("/coach/booking");
  //     }
  //   }
  // }

  // const addSlot = async (item) => {
  //   const newSlot = {
  //     id: slots.length + 1,
  //     fromTime: "",
  //     toTime: "",
  //   };

  //   let check_id = 0;

  //   const payload = {
  //     id: slots.length + 1,
  //     start_time: formatTime(startDate, fromTime),
  //     end_time: formatTime(startDate, toTime),
  //   };
  //   const isConflict =false;
  //   // const isConflict = bookedEvents.some(slot => {
  //   //   const existingStart = new Date(slot.start_time);
  //   //   const existingEnd = new Date(slot.end_time);
  //   //   const newStart = new Date(payload.start_time);
  //   //   const newEnd = new Date(payload.end_time);

  //   //   return (newStart <= existingEnd && newEnd >= existingStart);
  //   // });
  //   if (isConflict) {
  //     setFromTime("")
  //     setToTime("")
  //     removeSlot(check_id)
  //     toast.error("This time slot already exist.");
  //     return;
  //   }else{

  //       const isCheck = updateSlots.some(slot => {
  //       const existingStart = new Date(slot.start_time);
  //       const existingEnd = new Date(slot.end_time);
  //       const newStart = new Date(payload.start_time);
  //       const newEnd = new Date(payload.end_time);
  //   return (newStart <= existingEnd && newEnd >= existingStart);
  //  });

  //   if (isCheck) {
  //     setFromTime("")
  //     setToTime("")
  //     removeSlot(check_id)
  //      toast.error("This time slot overlaps with an existing one.");
  //   }else{
  //     if(item == 'time'){

  //     }else{
  //       setSlots([...slots, newSlot]);
  //       if(fromTime  && toTime){
  //       setAllSlot((prevUpdateSlots) => [...prevUpdateSlots, payload]);
  //       }
  //     }

  //     setFromTime("")
  //     setToTime("")
  //   }
  //   }

  // };

  const updateSlot = async () => {
    console.log("Updated slots:", updateSlots);
    // return;
    if (updateSlots.length === 0) {
      toast.error("Please select a time slot");
    } else {
      let counter = 0;
      for (let i = 0; i < updateSlots.length; i++) {
        counter++;
        await addAvailability(updateSlots[i]);
      }
      if (counter === updateSlots.length) {
        toast.success("Slot added successfully!");
        navigate("/coach/booking");
      }
    }
  };

  // Function to format date and time
  const formatTime = (date, time) => {
    const datePart = date.toISOString().split("T")[0];
    return `${datePart}T${time}:00.000000`;
  };

  // Update the 'fromTime' of a specific slot
  const handleFromTime = (id, time) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot) =>
        slot.id === id ? { ...slot, fromTime: time } : slot
      )
    );
  };

  // Update the 'toTime' of a specific slot
  const handleToTime = (id, time) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot) =>
        slot.id === id ? { ...slot, toTime: time } : slot
      )
    );
  };

  // Function to add a new slot dynamically with date and time
  const addSlot = () => {
    const newSlot = {
      id: slots.length + 1,
      fromTime: "", // Initial value for fromTime
      toTime: "", // Initial value for toTime
      date: startDate, // Use a date picker to set date
    };
    setSlots([...slots, newSlot]);
  };

  // Remove a slot
  const removeSlot = (id) => {
    setSlots(slots.filter((slot) => slot.id !== id));
  };

  useEffect(() => {
    console.log("Updated updateSlots:", updateSlots);
  }, [updateSlots]);

  useEffect(() => {
    // Assuming `defaultSlots` is an array of pre-existing slots you want to edit
    const defaultSlots = [
      { id: 1, start_time: "09:00 AM", end_time: "10:00 AM" },
    ];
    setAllSlot(defaultSlots);
  }, []);
  

  // const removeSlot = (id) => {
  //   setSlots(slots.filter((slot) => slot.id !== id));
  //   setAllSlot(updateSlots.filter((slot_id) => slot_id.id !== id));
  // };

  const defaultUserImage = (e) => {
    e.target.src = blog_card_img1;
  };
  // console.log(user);

  const handleBlur = (e) => {
    const fromDate = new Date(
      `1970-01-01T${fromTime.replace(/ (AM|PM)/, " $1")}`
    );
    const toDate = new Date(`1970-01-01T${toTime.replace(/ (AM|PM)/, " $1")}`);

    if (fromDate >= toDate) {
      toast.error("End time must be greater than start time.");
    } else {
      setToTime(toTime);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    console.log("datesdatesdatesdates",dates)
    setStartDate(start);
    setEndDate(end);
    console.log(startDate,endDate)
    addSlot("time");
  };
  return (
    <>
      <Row className="justify-content-between">
        <Col sm="auto">
          <Button variant="success" className="rounded-circle">
            <i className="bi bi-chevron-left"></i>
          </Button>
        </Col>
        <Col sm="auto">
          <div className="d-flex align-items-center">
            <span>
              <img src={update_availability} />
            </span>
            <h4 className="fs-20 fw-500 text_brand_color2 ms-2 m-0">
              Update Availability
            </h4>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="12">
          <h4 className="fs-24 fw-500 text_brand_color2">
            Update your availability
          </h4>
          <p className="fs-12 fw-400">
            Set your availability for each day of week at once{" "}
          </p>
        </Col>
      </Row>
      <Row>
        <div className="booking-component">
          <ul className="booking_user_list">
            <h5>Added Slots</h5>
            {bookedEvents.length > 0
              ? bookedEvents.map((item) => {
                  // Parse the original time as UTC
                  const adjustedTime = moment.utc(item.start_time);

                  const formattedStartTime = adjustedTime.format(
                    "MMMM Do YYYY, h:mm A"
                  );

                  const adjustedEndTime = moment.utc(item.end_time);

                  const formattedEndTime = adjustedEndTime.format(
                    "MMMM Do YYYY, h:mm A"
                  );
                  return (
                    <li key={item.id}>
                      <div className="booking_user_card">
                        <Row className="align-items-center">
                          <Col sm="6">
                            <div className="p-2 booking_user_card_lt">
                              <div className="d-flex align-items-center">
                                <div className="ps-2">
                                  {/* Display adjusted start and end times */}
                                  <p>{formattedStartTime}</p>
                                  <p>-</p>
                                  <p>{formattedEndTime}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          {item.slot_status === "booked" ? (
                            <Col sm="6">
                              <div className="text-end">
                                <Button
                                  className="px-4 btn-sm"
                                  variant="success"
                                  style={{ pointerEvents: "none" }}
                                >
                                  Booked
                                </Button>
                              </div>
                            </Col>
                          ) : item.slot_status === "un_booked" ? (
                            <Col sm="6">
                              <div className="text-end">
                                <Button
                                  className="px-4 btn-sm"
                                  variant="danger"
                                  style={{ pointerEvents: "none" }}
                                >
                                  Un Booked
                                </Button>
                              </div>
                            </Col>
                          ) : item.slot_status === "cancelled" ? (
                            <Col sm="6">
                              <div className="text-end">
                                <Button
                                  className="px-4 btn-sm"
                                  variant="secondary"
                                  style={{ pointerEvents: "none" }}
                                >
                                  Cancelled
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </Row>
      <Row className="g-4 justify-content-">
        <Col sm="7">
          <h4 className="fs-16 fw-500 mb-3">Slots for available hours</h4>
          {slots.map((slot) => (
            <div className="slot_avlble" key={slot.id}>
                <div className="slot_list">
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="time"
                            value={slot.fromTime}
                            onChange={(e) => handleFromTime(slot.id, e.target.value)}
                        />
                        <InputGroup.Text>To</InputGroup.Text>
                        <Form.Control
                            type="time"
                            value={slot.toTime}
                            onChange={(e) => handleToTime(slot.id, e.target.value)}
                            onBlur={() => {
                                const fromDate = new Date(`1970-01-01T${slot.fromTime}`);
                                const toDate = new Date(`1970-01-01T${slot.toTime}`);
                                if (fromDate >= toDate) {
                                    toast.error("End time must be greater than start time.");
                                }
                            }}
                        />
                    </InputGroup>
                </div>
                <Button variant="" onClick={() => removeSlot(slot.id)}>
                    <i className="text-danger fs-24 bi bi-trash"></i>
                </Button>
            </div>
        ))}
          <div className="slot_avlble">
            <Button variant="success" className="ms-2" onClick={addSlot}>
              <i className="bi bi-plus-lg"></i>
            </Button>
          </div>

          {/* <div className="slot_avlble">
            <div className="slot_list">
              <InputGroup className="mb-3">
                <Form.Control type="time" />
                <InputGroup.Text>To</InputGroup.Text>
                <Form.Control type="time" />
              </InputGroup>
            </div>
            <Button variant="success" className="ms-2">
              <i className="bi bi-plus-lg"></i>
            </Button>
          </div> */}
        </Col>
        <Col sm="auto">
          <div className="mb-3">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
              selectsRange
              inline
            />
          </div>
          <div className="text-end">
            <Button className="px-4" variant="success" onClick={updateSlot}>
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default AddAvailabilitySlots;
