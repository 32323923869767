import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-awesome-modal";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AgoraUIKit, { layout } from "agora-react-uikit";
import {
  createSession,
  coachSessionList,
  editSession,
  getMyFollowings,
  myContacts,
} from "../../../utils/api";
import EditIcon from "../../../assets/images/EditIcon.png";
import {
  UserContextProvider,
  useUserContext,
} from "../../../utils/hooks/UserContext";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../../Loader/Loader"
import { MultiSelect } from "react-multi-select-component";
import { isToday } from "date-fns"; // Utility to check today's date

export default function CreateLiveSession() {
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const [allSessions, setAllSessions] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [singleSessionDetails, setSingleSessionDetails] = useState({});
  const [allFollowers, setAllFollowers] = useState([]);
  const [poster_url, setPosterUrl] = useState(null);
  const [isLoading, setisLoading] = useState(false)
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [image, setImage] = useState([]);
  const [tags, setTags] = useState("");
  const [session_id, setSessionId] = useState("");
  const [audience_id, setAudienceId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [session_price, setSessionPrice] = useState("")
  const [sessionType, setSessionType] = useState("");
  const navigate = useNavigate();
  const [videoCallSessionId, setVideoCallSessionId] = useState("");
  const [videoCallSessionName, setVideoCallSessionName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const now = new Date();
    setCurrentTime(formatTime(now));
    getMyFollowers();
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
  }, []);

  const getMonth = (date) => {
    let months = [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[new Date(date).getMonth()];
  };

  const formatTime = (time) => {
    const date = new Date(time);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };

  const handleEditForm = (post) => {
    // console.log(post);
    // console.log(new Date(post.start_date));
    setSelectedDate(new Date(post.start_date));
    setTime(
      `${new Date(post.start_date).getHours()}:${new Date(
        post.start_date
      ).getMinutes()}`
    );
    setName(post.session_name);
    setSessionId(post?.id);
    setDuration(post?.duration);
    setTags(post?.tags);
    setImageUrl(process.env.REACT_APP_BASE_URL + post?.poster_url);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };
  const handleDateChange = (date) => {
    // console.log(date);
    setSelectedDate(date);
    setTime(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const openLiveSession = () => {};

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    // console.log(event.target.value);
    setTime(event.target.value);
  };

  const [time, setTime] = useState("");

  const dateConversion = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const onSubmitHandler = async () => {
    // console.log("function called");
    setisLoading(true)
    try {
      const start_end = new Date(selectedDate);
      start_end.setHours(start_end.getHours() + parseInt(duration));
      const formData = new FormData();
      formData.append("time", selectedTime);
      formData.append("start_date", dateConversion(selectedDate));
      formData.append("start_end", dateConversion(start_end));
      formData.append("session_name", name);
      formData.append("duration", duration == "20minute"? duration : duration + " Hour");
      formData.append("tags", tags);
      formData.append("status", "Active");
      formData.append("coach_id", user.coach_id);
      formData.append("session_price", session_price)
      formData.append("session_type", sessionType)
      formData.append("poster_url", poster_url);
      formData.append("audience_id", audience_id); 
      const { data } = await createSession(formData);
      if (data?.status) {
        setisLoading(false)
        toast.success(data?.message);
        setShowModal(false);
        setSelectedTime(null);
        setSelectedDate(new Date());
        setName("");
        setDuration("1");
        setTags("");
        // getData();
        navigate("/coach/my-activities")
      } else {
        toast.error(data?.message || "Something went wrong");
        setisLoading(false)
      }
      // console.log(data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
      setisLoading(false)
    } finally {
      setisLoading(false)
    }
  };

  const submitEditForm = async () => {
    setisLoading(true)
    try {
      const start_end = new Date(selectedDate);
      start_end.setHours(start_end.getHours() + parseInt(duration));
      const formData = new FormData();
      formData.append("time", selectedTime);
      formData.append("start_date", dateConversion(selectedDate));
      formData.append("start_end", dateConversion(start_end));
      formData.append("session_name", name);
      formData.append("duration", duration + " Hour");
      formData.append("tags", tags);
      formData.append("status", "Active");
      formData.append("session_price", session_price)
      formData.append("coach_id", user.coach_id);
      formData.append("poster_url", poster_url);
      formData.append("session_id", session_id);
      const { data } = await editSession(formData);
      if (data?.status) {
        toast.success(data?.message);
        setisLoading(false)
        setShowModal(false);
        setSelectedTime(null);
        setSelectedDate(new Date());
        setName("");
        setDuration("1");
        setTags("");
        // getData();
      } else {
        toast.error(data?.message || "Something went wrong");
        setisLoading(false)
      }
      // console.log(data.message);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message)
    } finally {
      setisLoading(false)
    }
  };

  const getMyFollowers = async () => {
    const data = await myContacts();
    // console.log(data);
    if (data) {
      setAllFollowers(data.data.data);
    }
  };

  return (
    <div>
      <div className="streaming-create-modal">
        <h3>Schedule live session</h3>

        {/* <Modal.Body> */}
        <div className="row">
          <div className="col-md-4 text-center border-end">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              inline
              dayClassName={(date) => (isToday(date) ? "highlight-today" : "")} 
            />
          </div>
          <div className="col-md-8 ps-5">
            <div className="form-group mb-3">
              {/* <label class="form-label">Session name</label> */}
              <input
                type="text"
                className="form-control"
                placeholder="Session Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group mb-2">
              <label for="" class="form-label">
                Session Type
              </label>
              <select
                id=""
                class="form-select"
                // style={{ padding: "13px" }}
                onChange={(e) => {
                  if (e.target.value === "free_session") {
                    setSessionType(e.target.value);
                    setDuration("20minute");
                  } else {
                    setSessionType(e.target.value);
                  }
                }}
              >
                <option value="">Select Session Type</option>
                <option value="free_session">Free Session</option>
                <option value="paid_session">Paid Session</option>
              </select>
            </div>
            {
              sessionType === "paid_session" && (
                <div className="form-group">
                <input
                    type="text"
                    placeholder="Session Price"
                    className="form-control"
                    value={session_price}
                    onChange={(e) => setSessionPrice(e.target.value)}
                  />
                </div>
              )
            }
            <div className="d-flex p-2">
              <div className="col-md-6 form-group mb-3">
                <label class="form-label">Time</label>
                <div>
                  <div className="d-flex align-items-center border rounded">
                    <div className="">
                      <input
                        type="time"
                        value={time}
                        onChange={handleChange}
                        className="border-0 form-control"
                      />
                    </div>
                    <div className="border">
                      <select
                        value={time.split(" ")[1]}
                        class="border-0 form-select"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-6 form-group mb-3"
                style={{
                  marginLeft: "10px",
                }}
              >
                <label for="" class="form-label">
                  Session Duration
                </label>
                <select
                  id=""
                  class="form-select"
                  style={{ padding: "13px" }}
                  value={duration}
                  onChange={(e) => {
                    if (
                      sessionType === "free_session" &&
                      e.target.value !== "20minute"
                    ) {
                      setDuration("20minute");
                      alert(
                        "With free session type only 20 minute session can be created!"
                      );
                    } else {
                      setDuration(e.target.value);
                    }
                  }}
                >
                  <option value="">Select Duration</option>
                  <option value="20minute">20 minute</option>
                  <option value="1">1 Hour</option>
                  <option value="2">2 Hour</option>
                  <option value="3">3 Hour</option>
                  <option value="4">4 Hour</option>
                </select>
              </div>
            </div>

            <div className="form-group mb-3" style={{ width: "100%" }}>
              <label for="" class="form-label">
                Select Audience
              </label>
              {/* <MultiSelect
                  variant="rd_btn"
                  id="tbg-radio-1"
                  options={allFollowers}
                  value={selectedAudience}
                  onChange={setSelectedAudience}
                  className="select-option fs-14"
                  // filterOptions={customFilterOptions}
                  overrideStrings={{ selectSomeItems: "Select Audience" }}
                /> */}
              <select
                id=""
                class="form-select"
                style={{ padding: "13px" }}
                onChange={(e) => setAudienceId(e.target.value)}
              >
                <option>Select Audience</option>
                {allFollowers.length > 0 &&
                  allFollowers.map((item) => (
                    <option value={item.value}> {item.label} </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-3">
              <label class="form-label">Poster Image (Optional) </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
              
                  if (file) {
                    if (!validImageTypes.includes(file.type)) {
                      alert('Please select a valid image file');
                      e.target.value = null;  // Reset the input value
                      return;
                    }
                    if (file.size > 300000) {
                      alert('File size must be less than 300 KB');
                      e.target.value = null;  // Reset the input value
                      return;
                    }
                    setImageUrl(URL.createObjectURL(file));
                    setPosterUrl(file);
                  }
                }}
              />
            </div>
          </div>
          {imageUrl != "" && (
            <>
              <div>
                <img src={imageUrl} height="300px" />
              </div>
            </>
          )}
          <div className="col-md-12">
            <div className="form-group mb-3">
              {/* <label class="form-label">Tags</label> */}
              <input
                type="text"
                placeholder="Tags"
                className="form-control"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button
              className="px-5 btn primary-button"
              onClick={() => {
                if (showEdit === false) {
                  onSubmitHandler();
                } else {
                  submitEditForm();
                }
              }}
            >
              { isLoading ? <Loader/> : "Submit" }
            </button>
          </div>
        </div>
        {/* </Modal.Body> */}
      </div>
    </div>
  );
}
